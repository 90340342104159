import React from 'react'

const Linkedin = () => {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.92836 19H1.11391V6.73633H4.92836V19ZM3.00063 5.0957C1.81117 5.0957 0.826799 4.07031 0.826799 2.83984C0.826799 1.65039 1.81117 0.666016 3.00063 0.666016C4.2311 0.666016 5.21547 1.65039 5.21547 2.83984C5.21547 4.07031 4.2311 5.0957 3.00063 5.0957ZM19.1608 19H15.3873V13.0527C15.3873 11.6172 15.3463 9.8125 13.3776 9.8125C11.4088 9.8125 11.1217 11.3301 11.1217 12.9297V19H7.30727V6.73633H10.9577V8.41797H10.9987C11.5319 7.47461 12.7623 6.44922 14.608 6.44922C18.4635 6.44922 19.2018 8.99219 19.2018 12.2734V19H19.1608Z" fill="#005645" />
        </svg>
    )
}

export default Linkedin
