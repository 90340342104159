import React from 'react'

const Logo = () => {
    return (
        <svg width="161" height="60" viewBox="0 0 161 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M76.5034 35.8823L64.5925 21.9035L75.4695 9H66.8672L60.0018 17.1474L53.1779 9H44.0379L54.8735 21.8621L43.0867 35.8823H51.689L59.4228 26.6182L67.3634 35.8823H76.5034Z" fill="#192644" />
            <path d="M102.803 35.8823V28.9343H89.1549C85.3914 28.9343 82.6204 26.2874 82.6204 22.4411C82.6204 18.5536 85.3914 15.9067 89.1549 15.9067H102.803V9H89.6512C81.4211 9 75.4243 14.4178 75.4243 22.4411C75.4243 30.4645 81.4211 35.8823 89.6512 35.8823H102.803Z" fill="#192644" />
            <path d="M132.384 25.2534V19.5048H116.503V25.2534H132.384ZM132.715 35.8823V28.9343H114.104V15.9067H132.674V9H106.867V35.8823H132.715Z" fill="#192644" />
            <path d="M160.572 35.8823V28.9343H144.194V9H136.915V35.8823H160.572Z" fill="#192644" />
            <path d="M18.5302 23.471C12.5004 20.7082 6.00582 17.5787 0.100429 14.6195L0.0676938 14.6064V14.5736C0.0807878 13.2839 0.0284118 10.5734 0.0284118 9.30986C-0.0174172 8.58315 0.768222 9.17237 1.08902 9.29022L6.95513 12.0465C10.8833 13.8993 14.7919 15.8045 18.6808 17.7489V17.821L18.5302 23.4841V23.471ZM18.5106 23.4383L18.4451 17.8079C14.9883 16.433 10.556 14.1088 6.8111 12.3411L0.997367 9.47354L0.290292 9.12655C0.231369 9.12655 0.205181 9.13964 0.211728 9.30986C0.198634 10.5669 0.178993 13.2904 0.172446 14.5736C5.30529 16.8389 13.2337 20.7998 18.5106 23.4448V23.4383Z" fill="#192644" />
            <path d="M18.5302 35.537C24.0951 38.1165 29.6339 40.7615 35.153 43.4523C35.4345 43.6291 35.991 43.7535 36.1743 44.1136C36.4166 44.5326 36.1154 45.0891 35.6964 45.2004L33.6145 46.1759C29.5619 48.0614 25.1623 50.2023 21.1686 52.1533L19.0998 53.1681C18.8968 53.2532 18.7659 53.371 18.5236 53.4103C18.3469 53.3907 18.3076 53.3252 18.2094 53.2925C17.3321 52.88 13.692 51.1582 12.7492 50.713C10.5101 49.6392 3.4721 46.2741 1.33778 45.2397L0.820567 44.9843L0.565235 44.8599L0.434295 44.7945C0.355731 44.7552 0.290261 44.6897 0.257526 44.6046C0.13968 44.2707 0.408107 44.1267 0.676534 44.0154L1.19375 43.7666C4.991 41.9727 8.83409 40.1265 12.6444 38.3719L12.6575 38.4046C9.26619 40.133 5.77664 41.8418 2.35911 43.5374C1.92701 43.76 1.00388 44.2052 0.571782 44.4278C0.558688 44.4344 0.552141 44.4606 0.558688 44.4737L4.59818 46.4116C7.93715 48.009 14.8049 51.348 18.0523 52.9455C18.1505 52.9782 18.4516 53.1615 18.5236 53.1681C18.5433 53.1812 18.6546 53.1092 18.7397 53.0764C18.7659 53.0764 20.9591 52.0224 21.0835 51.9765C25.0968 50.091 29.5291 47.9698 33.5228 45.986L35.5851 44.9712C35.9321 44.8534 36.0958 44.5391 35.9321 44.2314C35.8339 44.0088 35.2447 43.8321 35.0221 43.7011C29.5291 41.0169 23.8791 38.3457 18.5236 35.5239L18.5302 35.537Z" fill="#192644" />
            <path d="M18.5302 59.0017C12.5005 56.2454 5.99929 53.1159 0.100444 50.1502L0.0677091 50.1371V50.1043C0.080803 48.8146 0.0284271 46.1041 0.0284271 44.8405C-0.0174019 44.1007 0.76169 44.7031 1.08904 44.8209L6.95515 47.5772C10.8833 49.43 14.7919 51.3352 18.6808 53.2796V53.3516L18.5302 59.0148V59.0017ZM18.5106 58.969L18.4451 53.3385C14.9883 51.9637 10.556 49.6395 6.80457 47.8718L0.990835 45.0042L0.28376 44.6507C0.224837 44.6507 0.198649 44.6638 0.211743 44.834C0.198649 46.091 0.179008 48.8146 0.172461 50.0978C5.3053 52.363 13.2337 56.324 18.5106 58.969Z" fill="#192644" />
            <path d="M18.5304 17.7683C24.1019 20.3478 29.6407 22.9928 35.1532 25.6836C35.4347 25.8604 35.9912 25.9847 36.168 26.3448C36.4102 26.7638 36.1025 27.3203 35.6835 27.4251L33.6016 28.4006C29.5424 30.2861 25.1429 32.427 21.1557 34.378L19.0869 35.3928C18.8905 35.4713 18.7334 35.6154 18.5042 35.635C18.3274 35.6088 18.2947 35.5499 18.19 35.5172C17.3127 35.1047 13.6725 33.3829 12.7298 32.9377C10.4907 31.8639 3.44612 28.4988 1.31835 27.4644L0.801136 27.209L0.545803 27.0846L0.408316 27.0126C0.336299 26.9733 0.270829 26.8948 0.244641 26.8162C0.146437 26.4889 0.427957 26.3514 0.68329 26.2466L1.2005 25.9978C4.99776 24.204 8.84085 22.3577 12.6512 20.6031L12.6643 20.6359C9.2664 22.3643 5.7834 24.0796 2.36587 25.7687C1.92722 25.9913 1.00409 26.4365 0.565444 26.6591C0.545803 26.6656 0.545803 26.6853 0.55235 26.7049L4.5853 28.6363C7.93081 30.2337 14.7921 33.5727 18.0459 35.1702C18.1507 35.2095 18.4453 35.3862 18.5239 35.3993C18.5435 35.4124 18.6482 35.3469 18.7334 35.3142C18.7595 35.3142 20.9528 34.2601 21.0772 34.2143C25.0905 32.3288 29.5228 30.2076 33.523 28.2238L35.5853 27.209C35.9323 27.0912 36.096 26.7835 35.9389 26.4692C35.8407 26.2466 35.2514 26.0699 35.0354 25.9389C29.5424 23.2546 23.8858 20.5835 18.5369 17.7617L18.5304 17.7683Z" fill="#192644" />
            <path d="M18.5299 41.2334C12.5001 38.4771 6.00551 35.3476 0.100121 32.3884L0.0673861 32.3753V32.3426C0.0804801 31.0463 0.0281041 28.3424 0.0281041 27.0722C0.00846315 26.9086 0.0477451 26.627 0.322719 26.6991L1.06908 27.0461L6.94173 29.8023C10.8765 31.6551 14.785 33.5669 18.6805 35.5113V35.5833L18.5299 41.2465V41.2334ZM18.5103 41.2007L18.4448 35.5702C14.988 34.1888 10.5491 31.8646 6.7977 30.097C6.47689 29.9398 0.309625 26.902 0.263796 26.8758C0.185232 26.8627 0.231061 26.9806 0.217967 27.0657C0.191779 28.3227 0.198326 31.0528 0.178685 32.336C5.31808 34.6013 13.2399 38.5622 18.5168 41.2072L18.5103 41.2007Z" fill="#192644" />
            <path d="M18.5235 0C24.1278 2.61225 30.151 5.48638 35.7356 8.19684L36.3052 8.47836L36.5932 8.61585L36.7373 8.68786C36.7373 8.68786 36.9206 8.76643 36.9271 8.90391C36.9337 8.96284 36.9271 9.02831 36.8879 9.08068C36.8158 9.17234 36.7111 9.19853 36.626 9.24436L36.3379 9.38184C35.3231 9.85323 33.3525 10.7829 32.3115 11.2739C27.8072 13.3297 22.9231 15.8241 18.4843 17.8798C13.6984 15.6015 5.92057 11.9614 1.21982 9.67646L0.643689 9.40148L0.355621 9.264L0.211587 9.19198L0.13957 9.15925C0.13957 9.15925 0.0479125 9.11342 0.0282715 9.06104C-0.0568394 8.87118 0.0610065 8.75988 0.237775 8.69441C0.309792 8.65513 0.591313 8.53073 0.669877 8.48491C5.61286 6.06252 13.4889 2.38965 18.5235 0ZM18.5235 0.032735C13.5282 2.53369 5.78308 6.38332 0.827005 8.79261L0.538937 8.9301L0.394903 9.00212L0.322886 9.03485L0.290151 9.05449C0.290151 9.05449 0.349074 9.00867 0.355621 8.97593C0.368715 8.9432 0.362168 8.90391 0.342527 8.87118C0.342527 8.84499 0.263963 8.80571 0.296698 8.8188C5.46882 11.2936 13.3449 15.1367 18.5628 17.6965C24.0557 15.0057 30.7533 11.8632 36.495 8.98248L36.6391 8.91046C36.6849 8.88427 36.6194 8.9301 36.6391 8.93665C36.7111 9.00866 36.5474 8.90391 36.4688 8.87118L36.1808 8.73369C30.3736 5.93158 24.298 2.9396 18.5235 0.032735Z" fill="#192644" />
            <path d="M14.6736 57.0702V15.8896L18.5232 17.7817L18.556 58.9558L14.6736 57.0702Z" fill="#005645" />
            <path d="M14.6736 15.8895L32.8284 6.94629L36.678 8.81218C36.7762 8.85801 36.7762 9.00205 36.678 9.05442L18.5232 17.7816L14.6736 15.8895Z" fill="#005645" />
            <path d="M14.6736 33.6971L32.8284 24.6885L36.9268 26.6788L18.5625 35.5499L14.6736 33.6971Z" fill="#005645" />
            <path d="M14.6736 51.3743L32.8284 42.4312L36.9268 44.4214L18.5625 53.2926L14.6736 51.3743Z" fill="#005645" />
            <path d="M36.9267 14.5735V8.93652L18.5231 17.7815V23.4512H18.5296L18.5362 35.5762L18.5231 41.2198L18.5427 41.2132L18.5558 54.9423L18.5231 58.9883L36.9267 50.104V44.4671L22.4054 51.4854V39.3473L36.9267 32.3355V26.6985L22.4054 33.7104V21.5788L36.9267 14.5735Z" fill="url(#paint0_linear_59_1374)" />
            <path d="M161 39H45V57H161V39Z" fill="url(#paint1_linear_59_1374)" />
            <path d="M66.8303 53C68.7333 53 70.0903 51.7846 70.0903 49.9385C70.0903 48.1538 68.7333 46.9077 66.8303 46.9077H62.3692C61.9793 46.9077 61.7141 46.6462 61.7141 46.2308C61.7141 45.8 61.9793 45.5692 62.3692 45.5692H69.9499V43H62.2444C60.357 43 59 44.2154 59 46.0308C59 47.8154 60.357 49.0462 62.2444 49.0462H66.7055C67.0955 49.0462 67.3762 49.3077 67.3762 49.7385C67.3762 50.1692 67.0955 50.4154 66.7055 50.4154H59.1248V53H66.8303Z" fill="white" />
            <path d="M76.8979 53V50.8923H74.7766C74.1527 50.8923 73.8563 50.5846 73.8563 49.9846V47.4462H76.9759V45.3231H73.8563V43.1385H71.267V50.1846C71.267 51.9692 72.2341 53 74.1059 53H76.8979Z" fill="white" />
            <path d="M80.8484 53V48.9692C80.8484 48.0923 81.3008 47.6615 82.1899 47.6615H84.3112V45.3231H82.1275C79.585 45.3231 78.2435 46.6615 78.2435 48.9846V53H80.8484Z" fill="white" />
            <path d="M90.6323 53C93.206 53 94.5318 51.6769 94.5318 49.3385V45.3231H91.9269V49.3385C91.9269 50.2 91.4746 50.6615 90.5699 50.6615H89.1972C88.3081 50.6615 87.8402 50.2 87.8402 49.3385V45.3231H85.2353V49.3385C85.2353 51.6769 86.5611 53 89.1348 53H90.6323Z" fill="white" />
            <path d="M103.354 53V50.6615H99.8289C98.9086 50.6615 98.2535 50.0462 98.2535 49.1538C98.2535 48.2615 98.9086 47.6615 99.8289 47.6615H103.354V45.3231H99.8445C97.4268 45.3231 95.6642 46.8769 95.6642 49.1538C95.6642 51.4308 97.4268 53 99.8445 53H103.354Z" fill="white" />
            <path d="M110.242 53V50.8923H108.121C107.497 50.8923 107.2 50.5846 107.2 49.9846V47.4462H110.32V45.3231H107.2V43.1385H104.611V50.1846C104.611 51.9692 105.578 53 107.45 53H110.242Z" fill="white" />
            <path d="M116.985 53C119.558 53 120.884 51.6769 120.884 49.3385V45.3231H118.279V49.3385C118.279 50.2 117.827 50.6615 116.922 50.6615H115.55C114.661 50.6615 114.193 50.2 114.193 49.3385V45.3231H111.588V49.3385C111.588 51.6769 112.914 53 115.487 53H116.985Z" fill="white" />
            <path d="M124.779 53V48.9692C124.779 48.0923 125.232 47.6615 126.121 47.6615H128.242V45.3231H126.058C123.516 45.3231 122.174 46.6615 122.174 48.9846V53H124.779Z" fill="white" />
            <path d="M137.142 53V50.8923H132.899C131.979 50.8923 131.323 50.2923 131.323 49.1385C131.323 47.9692 131.979 47.3077 132.899 47.3077H134.49C134.895 47.3077 135.083 47.5077 135.083 47.8769C135.083 48.2462 134.88 48.4154 134.474 48.4154H132.244V49.8154H135.02C136.814 49.8154 137.563 49.1231 137.563 47.7846C137.563 46.3385 136.689 45.3231 134.895 45.3231H132.914C130.497 45.3231 128.734 46.8769 128.734 49.1538C128.734 51.4308 130.497 53 132.914 53H137.142Z" fill="white" />
            <path d="M144.489 53C145.939 53 147 52.0923 147 50.6769C147 49.3538 146.08 48.3846 144.489 48.3846H141.369C141.01 48.3846 140.839 48.2154 140.839 47.9077C140.839 47.6 141.041 47.4308 141.369 47.4308H146.61V45.3231H140.886C139.45 45.3231 138.374 46.2154 138.374 47.6154C138.374 48.9231 139.31 49.9231 140.901 49.9231H143.958C144.333 49.9231 144.504 50.1077 144.504 50.4C144.504 50.7231 144.302 50.8923 143.958 50.8923H138.468V53H144.489Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_59_1374" x1="18.5231" y1="33.9591" x2="36.9267" y2="33.9591" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#005645" />
                    <stop offset="1" stopColor="#39AF25" />
                </linearGradient>
                <linearGradient id="paint1_linear_59_1374" x1="45" y1="47.9981" x2="161" y2="47.9981" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#005645" />
                    <stop offset="1" stopColor="#41B125" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default Logo
