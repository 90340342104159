import React from 'react'

const SimpleCube = () => {
    return (
        <svg width="470" height="542" viewBox="0 0 470 542" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.7" d="M78.8159 31.7587L70.5162 30H73.117L78.9307 31.2617L79.1984 30H79.7339L79.4279 31.3764L157.835 48.1992L161.736 30H162.31L158.485 48.3139L236.892 65.1367L244.312 30H244.886L237.237 65.2514L315.644 82.0742L326.888 30H327.424L316.217 82.1889L394.701 99.0117L409.426 30H410L378.14 178.308V178.576L-3.18646 96.6795V96.1442L61.9488 110.291L78.8159 31.7587ZM377.643 177.926L394.51 99.547L316.103 82.7242L299.083 161.103L377.643 177.926ZM298.738 160.988L315.567 82.6095L237.198 65.7867L220.331 144.166L298.738 160.988ZM219.796 144.051L236.663 65.672L158.256 48.8492L141.427 127.19L219.796 144.051ZM140.891 127.075L157.567 48.7345L79.3131 31.8734L62.4843 110.291L140.891 127.075Z" fill="#D1D2D4" />
            <path opacity="0.7" d="M193.357 131.294L105.082 542H104.546L107.95 526.209L56.737 515.198V514.663L108.065 525.674L124.894 447.333L56.737 432.69V432.04L125.009 446.76L141.837 368.419L63.4303 351.749L56.737 383.025V380.463L62.9331 351.635L56.737 350.297V349.723L63.0479 351.099L79.8767 272.568L56.9283 267.597V267.062L79.8767 272.032L96.7056 193.653L56.6223 185.051V184.515L96.935 193.156L113.764 114.701L56.737 102.466V101.893L193.089 131.256L193.357 131.294ZM63.5451 351.061L141.952 367.884L158.781 289.505L80.4122 272.682L63.5451 351.061ZM80.5269 272.147L158.896 288.97L175.763 210.629L97.3558 193.768L80.5269 272.147ZM114.299 114.892L97.4705 193.271L175.878 210.285L192.706 131.906L114.299 114.892Z" fill="#D1D2D4" />
        </svg>


    )
}

export default SimpleCube
