import React from "react";

const Mail = () => {
  return (
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8846 1H3.11538C1.94709 1 1 1.95139 1 3.125V15.875C1 17.0486 1.94709 18 3.11538 18H20.8846C22.0529 18 23 17.0486 23 15.875V3.125C23 1.95139 22.0529 1 20.8846 1Z"
        stroke="#005645"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4 4L12 11L20 4"
        stroke="#005645"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Mail;
