import React from "react";

const Phone = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3445 17.4554C20.5429 16.6478 18.6013 15.4691 17.6593 14.9941C16.4326 14.3762 16.3317 14.3258 15.3675 15.0421C14.7243 15.5201 14.2967 15.9471 13.5441 15.7866C12.7914 15.6261 11.1558 14.721 9.72358 13.2935C8.2914 11.8659 7.33376 10.183 7.17272 9.43287C7.01169 8.68276 7.44583 8.26025 7.91935 7.61564C8.58672 6.70702 8.53624 6.55559 7.96579 5.32896C7.52105 4.37491 6.30797 2.45168 5.49723 1.65412C4.62994 0.797495 4.62994 0.948931 4.07111 1.18113C3.61615 1.37254 3.17968 1.60521 2.76716 1.87622C1.95945 2.41281 1.51117 2.85854 1.19767 3.52839C0.88418 4.19824 0.743336 5.76863 2.36229 8.70951C3.98125 11.6504 5.1171 13.1542 7.46804 15.4984C9.81899 17.8426 11.6268 19.1031 14.2695 20.5851C17.5387 22.416 18.7927 22.0591 19.4646 21.7461C20.1365 21.4331 20.5843 20.9889 21.1219 20.1813C21.3936 19.7695 21.6268 19.3335 21.8186 18.8789C22.0513 18.3222 22.2027 18.3222 21.3445 17.4554Z"
        stroke="#005645"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default Phone;
