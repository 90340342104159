import React from "react";

const Tel = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9994 13.8855V16.4764C19.0005 16.7169 18.9488 16.955 18.8479 17.1754C18.7469 17.3957 18.5988 17.5936 18.413 17.7562C18.2273 17.9188 18.008 18.0426 17.7693 18.1196C17.5305 18.1967 17.2775 18.2253 17.0265 18.2037C14.2415 17.9149 11.5664 17.0068 9.21601 15.5523C7.02929 14.2263 5.17534 12.4572 3.7858 10.3705C2.25627 8.11746 1.30442 5.55229 1.00735 2.8828C0.98473 2.64398 1.01447 2.40328 1.09468 2.17603C1.17489 1.94877 1.30381 1.73995 1.47323 1.56285C1.64265 1.38574 1.84885 1.24424 2.07872 1.14735C2.30858 1.05046 2.55707 1.00031 2.80837 1.00008H5.52347C5.96269 0.995959 6.3885 1.14438 6.72152 1.41768C7.05455 1.69098 7.27207 2.07051 7.33354 2.48553C7.44814 3.31468 7.66067 4.12879 7.96707 4.91234C8.08883 5.22146 8.11519 5.5574 8.043 5.88037C7.97082 6.20334 7.80313 6.4998 7.5598 6.73461L6.41041 7.83142C7.69877 9.99357 9.57482 11.7838 11.8406 13.0132L12.99 11.9164C13.2361 11.6842 13.5467 11.5242 13.8852 11.4553C14.2236 11.3864 14.5757 11.4116 14.8996 11.5278C15.7207 11.8202 16.5739 12.023 17.4428 12.1323C17.8824 12.1915 18.2839 12.4028 18.5709 12.7261C18.858 13.0493 19.0105 13.4619 18.9994 13.8855Z"
        stroke="#192644"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Tel;
