export const PARTNER_LIST_REQUEST = "PARTNER_LIST_REQUEST";
export const PARTNER_LIST_SUCCESS = "PARTNER_LIST_SUCCESS";
export const PARTNER_LIST_FAIL = "PARTNER_LIST_FAIL";

export const PARTNER_CREATE_REQUEST = "PARTNER_CREATE_REQUEST";
export const PARTNER_CREATE_SUCCESS = "PARTNER_CREATE_SUCCESS";
export const PARTNER_CREATE_FAIL = "PARTNER_CREATE_FAIL";
export const PARTNER_CREATE_RESET = "PARTNER_CREATE_RESET";

export const PARTNER_UPDATE_REQUEST = "PARTNER_UPDATE_REQUEST";
export const PARTNER_UPDATE_SUCCESS = "PARTNER_UPDATE_SUCCESS";
export const PARTNER_UPDATE_FAIL = "PARTNER_UPDATE_FAIL";
export const PARTNER_UPDATE_RESET = "PARTNER_UPDATE_RESET";

export const PARTNER_DELETE_REQUEST = "PARTNER_DELETE_REQUEST";
export const PARTNER_DELETE_SUCCESS = "PARTNER_DELETE_SUCCESS";
export const PARTNER_DELETE_FAIL = "PARTNER_DELETE_FAIL";
export const PARTNER_DELETE_RESET = "PARTNER_DELETE_RESET";

export const PARTNER_GET_MODEL_REQUEST = "PARTNER_GET_MODEL_REQUEST";
export const PARTNER_GET_MODEL_SUCCESS = "PARTNER_GET_MODEL_SUCCESS";
export const PARTNER_GET_MODEL_FAIL = "PARTNER_GET_MODEL_FAIL";
